<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="selectAllRows"
                  class="ml-1"
              >
                <span class="align-middle">全选</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="clearSelected"
                  class="ml-1"
              >
                <span class="align-middle">取消全选</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="allChangeStatus(4)"
                  class="ml-1"
                  v-if="user.role_id==27||user.role_id==14||user.role_id==11||user.role_id==12"
              >
                <span class="align-middle">仓库财务批量审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="allChangeStatus(3)"
                  class="ml-1"
                  v-if="user.role_id==29||user.role_id==33||user.role_id==14"
              >
                <span class="align-middle">业务负责人批量审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="allChangeStatus(1)"
                  class="ml-1"
                  v-if="tabStatus == 2&&(user.role_id==27||user.role_id==14||user.role_id==11||user.role_id==12)"
              >
                <span class="align-middle">仓库财务批量驳回</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="allChangeStatus(1)"
                  class="ml-1"
                  v-if="tabStatus == 4&&(user.role_id==29||user.role_id==33||user.role_id==14)"
              >
                <span class="align-middle">业务负责人批量驳回</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用编号"
                    label-for="cost_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="wh_name"
                      size="sm"
                      v-model="condition.costNo"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订货单编号"
                    label-for="cost_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="condition.orderNo"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="condition.channelName"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="店铺名称"
                    type="input"
                    v-on:change="fromChildren($event,['storeName','storeId'])"
                    :params="['storeName','storeId']"
                    :value="condition.storeName"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用科目"
                    label-for="purchase_team_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('sales_cost_type')"
                      v-model="condition.statementCostType"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="purchase_team_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('brand')"
                      v-model="condition.brandName"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    variant="outline-primary"
                    @click="exportToExcel"
                >
                  <span class="align-right">导出</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>
      <xy-tab
          :tabs="getCodeOptions('sales_cost_status')"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      ></xy-tab>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowEditSelected"
          v-model="selectTable"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(cost_no)="data">
          <b-link
              :to="{ name: 'apps-salescost-edit', query: { id: data.item.id,readonly:((data.item.status==3||data.item.status==2||data.item.status==4)||!isEmpty(data.item.pre_order_id))?1:0 } }"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.cost_no }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ getCodeLabel('sales_cost_type', data.item.type) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(pre_order_no)="data">
          {{ data.item.ext.psoOrderNo }}
        </template>

        <template #cell(statementNo)="data">
          <b-link
              :to="{ name: 'apps-datacentersales-view-offModalList', query: { id: data.item.ext.statementId,readonly:false,type:2 },params:{readonly:false} }"
              class="font-weight-bold d-block text-nowrap"
              target="_blank"
          >
            {{ data.item.ext.statementNo }}
          </b-link>
          <br>
          {{ data.item.ext.time }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('sales_cost_status', data.item.status)}`"
          >
            {{ getCodeLabel("sales_cost_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'sales_cost'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(remark)="data">
          {{ isEmpty(data.item.remark) ? '' : data.item.remark.substring(0, 10) }}
          <feather-icon
              @click="showRemark(data.item)"
              icon="EditIcon"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+(isEmpty(data.item.remark) ? '' : data.item.remark)+
              '</div>'"
              variant="success"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--单菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-salescost-edit', query: { id: data.item.id } }" v-if="isEmpty(data.item.pre_order_id)&&data.item.status === 1">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status === 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,4,'审核通过')" v-if="data.item.status === 2&&(user.role_id==27||user.role_id==14||user.role_id==11||user.role_id==12)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">仓库财务审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')" v-if="data.item.status === 4&&(user.role_id==29||user.role_id==33||user.role_id==14)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">业务负责人审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,1,'驳回')" v-if="(data.item.status === 2)&&(user.role_id==27||user.role_id==14||user.role_id==11||user.role_id==12)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">仓库财务驳回</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,1,'驳回')" v-if="(data.item.status === 4)&&(user.role_id==29||user.role_id==33||user.role_id==14)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">业务负责人驳回</span>
            </b-dropdown-item>

            <b-dropdown-item @click="del(data.item)" v-if="isEmpty(data.item.pre_order_id)&&data.item.status === 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status === 3">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

            <b-dropdown-item  @click="attachWh(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">附件上传</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>


      <b-modal
          id="modal-remark"
          ok-only
          ok-title="确认"
          @ok="saveRemark"
          cancel-title="取消"
          centered
          size="lg"
          title="修改备注"
          ref="remarkModal"
      >
        <b-card>
          <b-form-textarea
              id="textarea"
              v-model="remarkInfo"
              rows="3"
              max-rows="6"
          />
        </b-card>
      </b-modal>

      <b-modal
          id="attachModal"
          centered
          title="附件上传"
          ref="attachModal"
          @ok="saveAttach"
          cancel-title="取消"

      >
        <attachment-upload :theme="'files'"
                           :attachment_id="'attachments'"
                           :id="attachmentsId"
                           :object_type="'sales_order'"
                           :object_id="salesCost.cost_id"
                           @change="attachLoaded"
        />
      </b-modal>


    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty} from '@core/utils/filter'
import salescostUseList from './salescostUseList'
import salescostStore from './salescostStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import XyTab from "@/views/components/xy/XyTab";
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import {getUserData} from "@/auth/utils";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    AttachmentUpload,
    XyTab,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salescost/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    searchByCondition() {
      this.refetchData();
    },
    onRowEditSelected(items) {
      this.selectTable = items
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
    showRemark(item) {
      this.salesCost = item
      this.remarkInfo = item.remark
      this.$refs['remarkModal'].show()
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      status: '2',
      selectTable:[],
      user:{},
      salesCost:{},
      remarkInfo:"",
      attachmentsId:"",
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salescost')) store.registerModule('salescost', salescostStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salescost')) store.unregisterModule('salescost')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('salescost/changeStatus', {id: params.cost_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const del = function (params) {
      store.dispatch('salescost/del', {id: params.cost_id}).then(res => {
        if (res.data.code==0){
          toast.success("操作成功")
          refetchData()
        }else {
          toast.error(res.data.data)
          refetchData()
        }
      })
    }

    const allChangeStatus = function (params) {
      if (this.selectTable.length == 0){
        toast.error("请选择单据！")
        return
      }
      const idArray = []
      for (let i = 0; i < this.selectTable.length; i++) {
        idArray.push(this.selectTable[i].cost_id)
      }
      store.dispatch('salescost/allChangeStatus', {ids: idArray.join(','), status: params}).then(res => {
        if (res.data.code == 0) {
          toast.success("操作成功")
          refetchData()
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
    }

    const saveRemark = function () {
      this.salesCost.remark = this.remarkInfo
      store.dispatch('salescost/saveRemark', {id:this.salesCost.cost_id,remark:this.salesCost.remark}).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
        refetchData()
      })
    }

    const attachWh = function (params) {
      this.salesCost = params
      this.attachmentsId = params.attachments
      this.$refs['attachModal'].show()
    }

    const saveAttach = function () {
      store.dispatch('salescost/saveAttachments', {id: this.salesCost.cost_id,attachments:this.attachmentsId})
          .then(res => {
            if (res.data.code === 0) {
              toast.success(res.data.data)
              this.refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const attachLoaded = function (id, attachment, result) {
      this.attachmentsId = result
    }

    const exportToExcel = function(){
      const condition = this.condition
      condition.status = this.tabStatus
      condition.type = isEmpty(condition.statementCostType) ? null : condition.statementCostType.value
      condition.brand_id = isEmpty(condition.brandName) ? null : condition.brandName.value
      condition.brand_name = isEmpty(condition.brandName) ? null : condition.brandName.label
      condition.channel_id = condition.channelId
      condition.channel_name = condition.channelName
      condition.cost_no = condition.costNo
      condition.store_id = condition.storeId
      condition.store_name = condition.storeName
      store.dispatch('salescost/exportToExcel', condition)
          .then(res => {
            toast.success("导出成功")
            let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
            let filename = "费用单-"+new Date().getTime();
            /*-------------*/
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = URL.createObjectURL(data);
            link.setAttribute('download',filename+'.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
    }

    const {
      condition,
      tabStatus,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salescostUseList()

    return {
      condition,
      tabStatus,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      exportToExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,isEmpty,
      toDate,
      advanced_search,
      fromChildren,
      changeStatus,
      del,
      allChangeStatus,
      saveRemark,
      attachWh,
      saveAttach,
      attachLoaded,
    }
  },
  created() {
    this.user = getUserData()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
