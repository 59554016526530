import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function salescostUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'id', label: 'ID', sortable: true},
        {key: 'cost_no', label: '费用编号', sortable: true},
        {key: 'pre_order_no', label: '销售前置单编号', sortable: true},
        // { key: 'pre_item_id', label: '销售前置单明细ID', sortable: true },
        // { key: 'store_id', label: '店铺ID', sortable: true },
        {key: 'store_name', label: '店铺名称', sortable: true},
        // { key: 'channel_id', label: '终端客户ID', sortable: true },
        {key: 'channel_name', label: '终端客户', sortable: true},
        {key: 'type', label: '费用科目', sortable: true},
        {key: 'amount', label: '费用金额', sortable: true},
        // { key: 'brand_id', label: '品牌ID', sortable: true },
        {key: 'brand_name', label: '品牌名称', sortable: true},
        { key: 'remark', label: '备注', sortable: true },
        { key: 'attachments', label: '附件', sortable: true },
        {key: 'status', label: '审核状态', sortable: true},
        // { key: 'state', label: '状态', sortable: true },
        {key: 'add_time', label: '创建时间', sortable: true},
        {key: 'creator', label: '申请人', sortable: true},
        {key: 'modify_time', label: '修改时间', sortable: true},
        {key: 'updator', label: '审核人', sortable: true},
        {key: 'actions', label: '操作'},
        {key: 'statementNo', label: '核销日期', sortable: true},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('cost_id')
    const isSortDirDesc = ref(true)
    const condition = ref({})
    const tabStatus = ref(2)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey, tabStatus], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('salescost/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                status: tabStatus.value,
                cost_no: condition.value.costNo,
                orderNo: condition.value.orderNo,
                channel_id: condition.value.channelId,
                store_id: condition.value.storeId,
                type: isEmpty(condition.value.statementCostType) ? null : condition.value.statementCostType.value,
                brand_id: isEmpty(condition.value.brandName) ? null : condition.value.brandName.value,
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '销售费用单列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        condition,
        refetchData,
        tabStatus,

        // Extra Filters
    }
}
